import { icon } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'

const faChevronRightIcon = icon(faChevronRight)
const faChevronDownIcon = icon(faChevronDown)

const setupToggleCollapse = (button: string, target: string): void => {
  const button_item = document.getElementById(button)
  const target_item = document.getElementById(target)
  if (button_item && target_item) {
    button_item.addEventListener('click', () => {
      if (target_item.className.indexOf('show') >= 0) {
        // close
        target_item.classList.remove('show')
        const svg = button_item.getElementsByTagName('svg')
        if (svg) {
          button_item.removeChild(svg[0])
        }
        button_item.appendChild(faChevronRightIcon.node[0])
        const next_item = button_item.nextElementSibling as HTMLElement
        next_item.style.display = ''
      } else {
        // open
        target_item.classList.add('show')
        const svg = button_item.getElementsByTagName('svg')
        if (svg) {
          button_item.removeChild(svg[0])
        }
        button_item.appendChild(faChevronDownIcon.node[0])
        const next_item = button_item.nextElementSibling as HTMLElement
        next_item.style.display = 'none'
      }
    })
    button_item.appendChild(faChevronDownIcon.node[0])
  }
}
setupToggleCollapse('switchCollapseTop', 'collapseTop')
setupToggleCollapse('switchCollapsePrecision', 'collapsePrecision')
if (location.search.indexOf("collapse=true") >= 0) {
  const collapse_items = document.querySelectorAll('.collapse')
  if (collapse_items) {
    collapse_items.forEach(item => item.classList.remove('show'))
  }
  const switch_collapse_top_item = document.getElementById('switchCollapseTop')
  if (switch_collapse_top_item) {
    const svg = switch_collapse_top_item.getElementsByTagName('svg')
    if (svg) {
      switch_collapse_top_item.removeChild(svg[0])
    }
    switch_collapse_top_item.appendChild(faChevronRightIcon.node[0])
    const next_item = switch_collapse_top_item.nextElementSibling as HTMLElement
    next_item.style.display = ''
  }
  const switch_collapse_precision_item = document.getElementById('switchCollapsePrecision')
  if (switch_collapse_precision_item) {
    const svg = switch_collapse_precision_item.getElementsByTagName('svg')
    if (svg) {
      switch_collapse_precision_item.removeChild(svg[0])
    }
    switch_collapse_precision_item.appendChild(faChevronRightIcon.node[0])
    const next_item = switch_collapse_precision_item.nextElementSibling as HTMLElement
    next_item.style.display = ''
  }
}